import { forwardRef } from 'react';
import { useInView } from 'react-intersection-observer';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { SendFillIcon } from '@zep/icons';
import { cn } from '@zep/utils';

import { joinUtmQueryParams } from '../../../utils';

import S from './LandingIntro.module.scss';

export const LandingIntro = (props: LandingIntroProps) => {
  const { title, source, buttonText, buttonLink, onButtonClick } = props;
  const isInternal = buttonLink?.startsWith('/');
  const [firstContactButtonRef, isFirstButtonInView] = useInView();

  return (
    <>
      <section className={S.intro_visual}>
        {source && (
          <video
            key={source}
            autoPlay
            muted
            playsInline
            loop
            className={S.video}>
            <source src={source} />
          </video>
        )}
        <div className={S.background_shadow}>
          <div className={S.title_wrapper} data-motion="scale-in">
            {title && <h2 className={S.intro_title}>{title}</h2>}
            {buttonText && buttonLink && (
              <ReusableContactButton
                ref={firstContactButtonRef}
                buttonLink={buttonLink}
                buttonText={buttonText}
                onButtonClick={onButtonClick}
                isInternal={isInternal}
                className="min-w-[180px] lg:min-w-[220px]"
              />
            )}
          </div>
        </div>
      </section>
      {buttonText && buttonLink && !isFirstButtonInView && (
        <div className="fixed inset-x-0 bottom-[60px] z-50 flex justify-center">
          <ReusableContactButton
            buttonLink={buttonLink}
            buttonText={buttonText}
            onButtonClick={onButtonClick}
            isInternal={isInternal}
            className="!min-w-[149px] px-[10px] lg:!min-w-[174px]"
          />
        </div>
      )}
    </>
  );
};

const ReusableContactButton = forwardRef<
  HTMLAnchorElement,
  ReusableContactButtonProps
>(({ buttonLink, buttonText, onButtonClick, isInternal, className }, ref) => {
  const router = useRouter();

  return (
    <Link
      href={joinUtmQueryParams(buttonLink, router)}
      prefetch={false}
      ref={ref}
      className={cn(
        'from-[#6758FF] via-[#b334ff] text-[16px] lg:text-[18px] shadow-[0px_4px_8px_3px_rgba(42,39,65,0.10)] h-[50px] lg:h-[56px] flex items-center gap-[6px] justify-center to-[#6758FF] animate-gradient-move bg-gradient-to-br rounded-full text-white font-[600]',
        'hover:!from-primary hover:!to-primary',
        className,
      )}
      onClick={onButtonClick}
      target={isInternal ? '_self' : '_blank'}>
      <span>
        <SendFillIcon width={18} height={18} />
      </span>
      {buttonText}
    </Link>
  );
});
ReusableContactButton.displayName = 'ReusableContactButton';

type ReusableContactButtonProps = {
  buttonLink: string;
  buttonText: string;
  onButtonClick?: () => void;
  isInternal: boolean;
  className?: string;
};

type LandingIntroProps = {
  title: string;
  source: string;
  buttonText: string;
  buttonLink: string;
  onButtonClick?: () => void;
};
