import { useRouter } from 'next/router';

import { DownloadIcon } from '@zep/icons';
import { Spacing } from '@zep/ui';
import { Button } from '@zep/ui/experimental';
import { cn, isMobileDevice } from '@zep/utils';

export const LandingBusinessProposal = (
  props: LandingBusinessProposalProps,
) => {
  const router = useRouter();

  return (
    <div className="w-full bg-gray-50 px-[18px]">
      <div
        className={cn(
          'flex w-full relative rounded-[20px] bg-gray-50 gap-[20px] justify-between border border-[#27262E0A] items-center flex-col max-w-[1140px] px-[50px] py-[50px] mx-auto',
          'md:gap-[36px] md:py-[64px] lg:flex-row lg:px-[80px]',
          'bg-gradient-to-r from-[#E8F6FF] to-[#7B9FFF] via-[#C2DFFF]', // 그라데이션 설정
        )}>
        <img
          src={`/images/landing/${
            isMobileDevice() ? 'z-bg-mb' : 'z-bg-desktop'
          }.png`}
          className="absolute left-1/2 top-0 z-0 h-full -translate-x-[50%] object-cover lg:translate-x-[8%]"
          alt=""
        />
        <div
          className={cn(
            'flex flex-col gap-[12px] items-center lg:items-start',
          )}>
          <h2
            className={cn(
              ' text-gray-700 text-[26px] font-[800] text-center z-10',
              'md:text-h2',
              'lg:text-left',
            )}>
            {props.title}
          </h2>
          <p
            className={cn(
              'font-[600] text-gray-600 z-10',
              'text-subtitle text-[14px] text-center',
              'md:text-[16px]',
            )}>
            {props.description}
          </p>
        </div>
        <Button
          variant={'rounded'}
          className={cn(
            'text-subtitle z-10 flex h-[50px] min-w-[187px] items-center justify-center gap-[4px] bg-gray-700 text-[16px]',
            'md:h-[57px]',
            'hover:bg-gray-600',
          )}
          onClick={() => router.push(props.buttonLink)}>
          {props.buttonText}
          <DownloadIcon className="size-[20px]" />
        </Button>
      </div>
      <Spacing size={120} />
    </div>
  );
};

type LandingBusinessProposalProps = {
  title: string;
  description: string;
  buttonText: string;
  buttonLink: string;
};
